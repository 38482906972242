import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { BsCheck2 } from "react-icons/bs";
import anime from "../utils/anime";

interface IProps {}

const Service5Container = (props: IProps) => {
  const list = [
    {
      title: "Үйлчилгээгээ сонгох",
      desc: "Гэртээ эсвэл оффис дээрээ хүссэн шинжилгээ, эмчилгээгээ сонгож захиалаарай.",
    },
    {
      title: "Захиалгаа хийх",
      desc: "Үйлчилгээгээ сонгоход хүссэн газарт тань шаардлагатай бүх тоног төхөөрөмж, эм тариатайгаа очно. ",
    },
    {
      title: "Давтан үйлчлүүлэх",
      desc: "Жилийн 365 өдөр өглөөний 8 цагаас орой хүртэл хүссэн үедээ захиалгаа өгч цагаа хэмнэж, өөрийнхөө болон хайртай хүмүүсийнхээ эрүүл мэндэд анхаараарай. ",
    },
  ];

  const priceList = [
    {
      title: "Дуудлагын сувилагч",
      duration: "30 - 60 минут",
      price: "₮100,000",
      items: [
        "Даралт үзнэ",
        "Зохих түвшний эрүүл мэндийн зөвлөгөө өгнө",
        "Зөвхөн таны өөрийн дусал, тариаг хийж үйлчилнэ",
      ],
    },
    {
      title: "Хордлого тайлах эмчилгээ",
      duration: "60 минут",
      price: "₮280,000",
      items: [
        "Хордлого тайлах баталгаат дусал, тариа",
        "Элэг хамгаалах эмчилгээ",
        "Шингэн нөхөх",
        "Даралт үзэх, зөвлөгөө өгөх",
      ],
    },
    {
      title: "КОВИД-ын дараах цус шингэлэх эмчилгээ",
      duration: "360 минут",
      price: "₮280,000",
      items: [
        "Цус шингэлэх дусал, тариа",
        "Цус цэвэрлэж, холестерин бууруулах дусал",
        "Нөхөн сэргээх, бодисын солилцоо идэвхжүүлэх эмчилгээ",
      ],
    },
    {
      title: "Ерөнхий багц шинжилгээ",
      duration: "15 минут",
      price: "₮250,000",
      items: [
        "Биохими шинжилгээ",
        "Цусны дэлгэрэнгүй шинжилгээ",
        "Цус бүлэгнэлтийн шинжилгээ",
        "Шээсний шинжилгээ",
      ],
    },
    {
      title: "Эрдэсийн шинжилгээ",
      duration: "15 минут",
      price: "₮250,000",
      items: [
        "8 төрлийн эрдэс",
        "Цусны дэлгэрэнгүй шинжилгээ",
        "Цус багадалтын шинжилгээ",
        "Шээсний шинжилгээ",
      ],
    },
    {
      title: "Нойр булчирхайн багц шинжилгээ",
      duration: "15 минут",
      price: "₮250,000",
      items: [
        "Цусны дэлгэрэнгүй шинжилгээ",
        "Хавдрын маркер",
        "Шээсний шинжилгээ",
      ],
    },
  ];

  return (
    <div>
      <div className="relative overflow-hidden mb-10 lg:mb-0">
        <div className="mx-auto relative max-w-7xl px-8">
          <div className="absolute hidden lg:block top-0 -right-4/5 lg:right-[2rem] bottom-0">
            <StaticImage
              src="../images/v2/it-lab-0.png"
              alt={"Urtuu"}
              placeholder="none"
              quality={100}
              layout="fixed"
              height={578}
            />
          </div>
          <div
            className="absolute hidden lg:block top-0 transform translate-x-[20%] left-0 bottom-0 h-[578px] w-full right-0"
            style={{
              background:
                "linear-gradient(90deg, #fff 15.26%, #fff 35.56%, rgba(255, 255, 255, 0) 48.36%)",
            }}
          ></div>
          <div className="flex flex-col md:flex-row lg:min-h-[600px] items-center w-full relative">
            <div className="w-ful flex flex-col justify-center">
              <div className="block lg:hidden">
                <StaticImage
                  src="../images/v2/it-lab-0.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                />
              </div>
              <div className="text-left relative flex-col justify-center items-start">
                <span
                  className="font-bold my-5 lg:my-5 text-[21px] lg:text-[38px] uppercase mb-5 block"
                  {...anime("fade-right", 600)}
                >
                  ЛАБОРАТОРИЙН ШИНЖИЛГЭЭ
                </span>
                <p
                  className="text-[18px] lg:text-[24px] text-justify lg:text-left p-0 w-full mb-2 text-[#1E1E1E] lg:w-1/2"
                  {...anime("fade-right", 600, 300)}
                >
                  Лабораторийн мэргэшсэн сувилагч таны цуснаас дээж аван, цус
                  зөөвөрлөх тусгай стандартын дагуу лаборатори луу хүргэх бөгөөд
                  та хүссэн шинжилгээгээ өгч болно. Швейцарь, Япон, Герман улсын
                  лабораторийн бүрэн автомат тоног төхөөрөмжүүдээр оношилгоо
                  хийгдэх тул 100% найдвартай, зөв гаргах болно. Шинжилгээний
                  хариу 2-24 цагийн дотор таны утсанд мессежээр ирнэ.
                </p>
                {/* <button
                className="h-[85px] text-[21px] px-10 font-bold text-c1 items-center justify-center flex border-[2px] rounded-full border-c1"
                {...anime("fade-right", 600, 600)}
                onClick={() => {}}
              >
                Захиалах
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden -top-[28px]">
        <div className="mx-auto relative max-w-7xl px-8">
          <div className="flex flex-col md:flex-row lg:min-h-[600px] items-center w-full relative">
            <div className="absolute hidden lg:block top-0 left-[-70%] lg:left-0 bottom-0">
              <StaticImage
                src="../images/v2/it-lab-1.png"
                alt={"Urtuu"}
                placeholder="none"
                quality={100}
                layout="fixed"
                height={578}
              />
            </div>

            <div
              className="absolute hidden lg:block top-0 left-0 transform translate-x-[10%]  bottom-0 h-[578px] w-full right-0"
              style={{
                background:
                  "linear-gradient(90deg, rgba(255, 255, 255, 0) 15.26%, #FFFFFF 35.56%, #FFFFFF 48.36%)",
              }}
            ></div>

            <div className="w-ful flex flex-col justify-center">
              <div className="block lg:hidden">
                <StaticImage
                  src="../images/v2/it-lab-1.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                />
              </div>
              <div className="lg:text-right relative flex-col justify-center items-end">
                <span
                  className="font-bold my-5 lg:my-5 text-[21px] lg:text-[38px] uppercase mb-5 block"
                  {...anime("fade-right", 600)}
                >
                  Хордлого тайлах эмчилгээ
                </span>
                <p
                  className="text-[18px] lg:text-[24px] text-justify lg:text-right p-0 w-full mb-2 text-[#1E1E1E] lg:w-1/2 ml-auto"
                  {...anime("fade-right", 600, 300)}
                >
                  Архины шарталт, хордлого тайлах эмчилгээ, шингэн нөхөх зэрэг
                  эмчилгээг баталгаат эм тариа, дуслаар эмчлэх эрхийн лицензтэй
                  мэргэжлийн эмч, сувилагч нар хийж үйлчлэх тул та цагаа
                  хэмнэхээс гадна найдвартай үйлчилгээг байгаа газраасаа бүрэн
                  авах боломжтой.
                </p>
                {/* <button
                className="h-[85px] text-[21px] px-10 font-bold text-c1 items-center justify-center flex border-[2px] rounded-full border-c1"
                {...anime("fade-right", 600, 600)}
                onClick={() => {}}
              >
                Захиалах
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-auto max-w-7xl px-8">
          <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center m-5 mb-10">
            Үйлчилгээ авах дараалал
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            {list?.map((l, i) => (
              <div
                {...anime("fade-up", 500, 300 * i)}
                key={`f-lis-${i}`}
                className="bg-[#F8F8F8] rounded-[20px] flex flex-col  space-y-4 justify-center items-center p-10"
              >
                <div className="w-[100px] h-[100px] text-5xl font-bold bg-c1 rounded-full flex items-center justify-center text-white">
                  {i + 1}
                </div>
                <h3 className="font-bold text-[21px] text-center">{l.title}</h3>
                <p className="text-[#1E1E1E] text-center text-[18px]">
                  {l.desc}
                </p>
              </div>
            ))}
          </div>

          <div className="flex justify-center items-center my-20 space-x-10">
            <a href="https://apps.apple.com/mn/app/urtuu/id6446311351">
              <StaticImage
                src="../images/appstore.png"
                alt={"Urtuu App - App Store"}
                placeholder="none"
                height={68}
                quality={100}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=mn.urtuu.app">
              <StaticImage
                src="../images/playstore.png"
                alt={"Urtuu App - Play Store"}
                placeholder="none"
                height={68}
                quality={100}
              />
            </a>
          </div>
        </div>

        <div className="text-center mb-10 mt-28">
          <StaticImage src="../images/v2/itlab.png" alt="" height={120} />
        </div>

        <div className="bg-c2 py-[50px]">
          <div className="mx-auto max-w-7xl px-8">
            <div className="items-center lg:hidden grid-cols-1 lg:grid-cols-2 grid-rows-2 w-full mx-auto lg:w-[80%]">
              <div className="overflow-hidden rounded-tl-[150px] s-fkr">
                <StaticImage
                  src="../images/v2/it-lab-2.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
              <div className="p-10">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  Шинжилгээний лаборатори
                </h1>
                <p className="text-[18px] leading-[40px]">
                  Бид 2015 оноос үйл ажиллагаагаа эхлэн өдгөө 3 салбартай 70
                  гаруй ажилтантайгаар орчин үеийн дэвшилтэт тоног төхөөрөмжийг
                  ашиглан ард иргэдэд эрүүл мэндийн тусламж үйлчилгээ үзүүлж
                  байна.
                </p>
              </div>
              <div className="order-3 lg:order-4 overflow-hidden rounded-tr-[150px] s-fkr-2">
                <StaticImage
                  src="../images/v2/it-lab-3.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
              <div className="p-10 order-4 lg:order-3">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  Сувилагч нар
                </h1>
                <p className="text-[18px] leading-[40px]">
                  “Ай Ти Лаборатори”-ийн нарийн мэргэжлийн чадварлаг,
                  туршлагатай сувилагч нар танд үйлчлэх болно.
                </p>
              </div>
            </div>

            <div className="items-center hidden lg:grid grid-cols-1 lg:grid-cols-2 grid-rows-2 w-full mx-auto lg:w-[80%]">
              <div className="overflow-hidden rounded-tl-[150px] s-fkr">
                <StaticImage
                  src="../images/v2/it-lab-2.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
              <div className="p-10">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  Шинжилгээний лаборатори
                </h1>
                <p className="text-[18px] leading-[40px]">
                  Бид 2015 оноос үйл ажиллагаагаа эхлэн өдгөө 3 салбартай 70
                  гаруй ажилтантайгаар орчин үеийн дэвшилтэт тоног төхөөрөмжийг
                  ашиглан ард иргэдэд эрүүл мэндийн тусламж үйлчилгээ үзүүлж
                  байна.
                </p>
              </div>
              <div className="p-10 order-4 lg:order-3">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  Сувилагч нар
                </h1>
                <p className="text-[18px] leading-[40px]">
                  “Ай Ти Лаборатори”-ийн нарийн мэргэжлийн чадварлаг,
                  туршлагатай сувилагч нар танд үйлчлэх болно.
                </p>
              </div>
              <div className="order-3 lg:order-4 overflow-hidden rounded-tr-[150px] s-fkr-2">
                <StaticImage
                  src="../images/v2/it-lab-3.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-8 mb-20">
          <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center my-20">
            Үнийн мэдээлэл
          </h1>

          <div className="grid grid-cols-1 lg:grid-cols-3 w-full justify-center  mx-auto gap-10">
            {priceList?.map((p, i) => (
              <div className="bg-white shadow-xl p-10 flex flex-col items-center border border-gray-100 rounded-xl">
                <StaticImage
                  src="../images/f8.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  height={85}
                  layout="fixed"
                />
                <div className="w-full pt-10">
                  <span className="text-[18px]">{p.duration}</span>
                  <h3 className="font-bold text-[21px] text-left leading-[30px] my-2">
                    {p.title}
                  </h3>
                  <span className="text-[18px]">{p?.price}</span>
                  <ul className="mt-5 space-y-6">
                    {p?.items?.map((_, i) => (
                      <li
                        className="flex items-center space-x-2 mb-2 text-[18px]"
                        key={`b1-${i}`}
                      >
                        <BsCheck2 size={21} /> <span>{_}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-8">
          <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center my-20">
            Үйлчилгээ үзүүлэгчийн баримтлах зарчим
          </h1>
        </div>

        <div className="bg-c2">
          <div className="max-w-7xl mx-auto px-8 mb-20 py-5">
            <h3 className="text-center text-[21px]">
              Үйлчилгээ үзүүлэх сувилагч нар <br /> доорх зарчмыг баримтлан
              ажиллана.
            </h3>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-8 mb-20 flex justify-center">
          <ul className="text-[18px] pl-8 lg:pl-0  list-disc list-outside  space-y-5 mx-auto inline-block">
            <li>Үйлчлүүлэгчийн хувийн мэдээллийг чанд нууцална.</li>
            <li>
              Шаардлагатай тоног төхөөрөөмжтэйгээ ирж, тав тухтай орчинд
              үйлчилнэ.
            </li>
            <li>
              Үйлчлүүлэгтэйгээ зөв зохистой, ёс зүйтэй харилцахын хамт захиалсан
              цагийн дагуу бүтэн үйлчилгээ үзүүлнэ.
            </li>
            <li>
              Үйлчлүүлэгчтэй урьдчилж хэлэлцэлгүй дур мэдэн шийдвэр гаргахгүй
              байж, үргэлж үнэн зөв мэдээллээр хангана.
            </li>
            <li>Эрүүл мэндийн үзлэгт тогтмол хамрагдана.</li>
            <li>
              Мэдлэг дээшлүүлэх сургалтад хамрагдаж ур чадвараа дээшлүүлэхэд
              анхаарна.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Service5Container;
