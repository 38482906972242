import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Service3Container from "../containers/Service5";

interface IProps {}

const Service3 = (props: IProps) => {
  return (
    <Layout>
      <Service3Container />
    </Layout>
  );
};

export default Service3;

export const Head = () => (
  <SEO title={`Дуудлагын сувилагч, лаборатори`} description={`Дуудлагын сувилагч, лаборатори`} />
);
